import React, { useRef, useContext, useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { useIntl } from 'react-intl';

import { HOME_STEP_VISIBLE } from '../../../../utils/enums';

import * as Yup from 'yup';

import LogoiClips from '../../../../assets/logo-iclips.svg';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import SupportLink from '../../../../components/SupportLink';
import Api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';
import translate from '../../../../i18n/translate';
import InputSelect from '../../../../components/InputSelect';
import { REGIONS } from '../../../../i18n/regions';
import Captcha from '../../../../components/Captcha';
import { StepsContext } from '../../../../contexts/StepsContext';
import Alert from '../../../../components/Alert';

import {
    HeaderStep,
    StepVisibleControl,
    Footer,
    FormGroup,
    ServiceTerms,
} from '../../../../styles/global';

import { Error } from '../../../../components/Input/styles';

import { getErrorMessage } from '../../../../utils/manualValidations';

const {
    REACT_APP_RECAPTCHA_SITE_KEY
} = process.env;

const CustomerEmail = () => {
    const formRef = useRef(null);
    const { addStepValues, nextStep, region, stepVisible, currentLanguage, stepValues } = useContext(StepsContext);
    const { token } = stepValues;
    const [dataForm, setDataForm] = useState(null);
    const [alertError, setAlertError] = useState(false);
    const [reCaptchaError, setReCaptchaError] = useState(false);
    const [nextStepDisabledButton, setNextStepDisabledButton] = useState(false);
    const [isLoadingButton] = useState(false);

    const siteTitle = useIntl().formatMessage({
        id: 'site-title',
    });
    const companyContactNamePlaceholder = useIntl().formatMessage({
        id: 'client-step01-placeholder-example-name',
    });
    const telephoneTooltip = useIntl().formatMessage({
        id: 'client-step01-label-phone-tooltip',
    });
    const companyNamePlaceholder = useIntl().formatMessage({
        id: 'client-step01-placeholder-example-company',
    });

    const selectEmployeesNumber = [
        {
            value: '',
            label: useIntl().formatMessage({
                id: 'client-step04-mostImportantOptionsVal0',
            }),
        },
        {
            value: '0-4',
            label: '0-4'
        },
        {
            value: '5-10',
            label: '5-10'
        },
        {
            value: '11-25',
            label: '11-25'
        },
        {
            value: '26-50',
            label: '26-50'
        },
        {
            value: '51+',
            label: '51+'
        }
    ];

    const handleCaptcha = useCallback(() => {
        setReCaptchaError(false);
    }, []);

    useEffect(() => {
        document.title = siteTitle;
    }, [currentLanguage, siteTitle, dataForm])

    async function handleValidateStep(data) {
        try {
            debugger;
            setAlertError(false);
            // eslint-disable-next-line no-unused-expressions
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                EmailContact: Yup.string()
                    .required(getErrorMessage('EmptyDefault', currentLanguage))
                    .email(getErrorMessage('InvalidEmailContact', currentLanguage)),
                CompanyContactName: Yup.string().required(getErrorMessage('EmptyDefault', currentLanguage)),
                Telephone: Yup.string()
                    .min(14, getErrorMessage('MinTelephone', currentLanguage))
                    .max(15, getErrorMessage('MaxTelephone', currentLanguage))
                    .required(getErrorMessage('EmptyDefault', currentLanguage)),
                CompanyName: Yup.string().required(getErrorMessage('EmptyDefault', currentLanguage)),
                EmployeesNumber: Yup.string().required(getErrorMessage('selectAtLeastOneOption', currentLanguage)),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            if (!stepValues.reCaptcha) {
                setReCaptchaError(true);
                return;
            } else {
                let addLanguage = { language: currentLanguage };
                let finalObj = Object.assign(addLanguage, data);
                finalObj.captchaResponse = window.getCaptchaValue();

                //Marketplace flag
                finalObj.Marketplace = Boolean(
                    typeof token === 'string' &&
                    token.toLowerCase() === 'marketplace',
                );

                delete finalObj.reCaptcha;
                setDataForm(data);
                addStepValues(data);

                setNextStepDisabledButton(true);

                Api.post('/Customer', finalObj)
                    .then(res => {
                        if (res.status === 201) {
                            nextStep({ isLastScreen: true });
                        } else {
                            setNextStepDisabledButton(false);
                        }
                    })
                    .catch(err => {
                        setAlertError({
                            message : ((err?.response?.data.length && err.response.data[0]?.message) ? err.response.data[0].message : err.message),
                        });

                        setNextStepDisabledButton(false);
                    });
            }
        } catch (err) {
            const errors = getValidationErrors(err);
            // eslint-disable-next-line no-unused-expressions
            formRef.current?.setErrors(errors);
        }
    }

    return (
        <StepVisibleControl
            visible={stepVisible === HOME_STEP_VISIBLE.CUSTOMER_EMAIL}
        >
            <Form ref={formRef} onSubmit={handleValidateStep}>
                <HeaderStep>
                    <img src={LogoiClips} alt="LogoiClips" width="166" height="54" />

                    <h3 style={{ textAlign: 'center' }}>{translate('text-initial')}</h3>
                </HeaderStep>

                <FormGroup>
                    <Input
                        name="CompanyContactName"
                        id="CompanyContactName"
                        placeholder={`${companyContactNamePlaceholder}`}
                        type="text"
                        label={translate('client-step01-label-name')}
                        maxLength="100"
                        lang={currentLanguage}
                    />
                </FormGroup>

                <FormGroup>
                    <Input
                        name="EmailContact"
                        id="EmailContact"
                        placeholder={`${useIntl().formatMessage({
                            id: 'client-step01-placeholder-example-email',
                        })}`}
                        type="email"
                        label={translate('client-step01-label-email')}
                        maxLength="50"
                        lang={currentLanguage}
                    />
                </FormGroup>

                <FormGroup>

                    <Input
                        type="tel"
                        mask={region === REGIONS.BRAZIL ? `maskTelephone` : `maskTelephoneNA`}
                        name="Telephone"
                        id="Telephone"
                        placeholder={region === REGIONS.BRAZIL ? `31 98765-4320` : `555 555-5555`}
                        label={translate('client-step01-label-phone')}
                        width={'50%'}
                        maxLength={region === REGIONS.BRAZIL ? 13 : 14}
                        lang={currentLanguage}
                        tooltip={telephoneTooltip}
                    />
                </FormGroup>

                <FormGroup>
                    <label>{translate('client-step04-nameOfCompany')}</label>
                    <Input
                        name="CompanyName"
                        type="text"
                        placeholder={`${companyNamePlaceholder}`}
                        maxLength="100"
                        lang={currentLanguage}
                    />
                </FormGroup>

                <FormGroup>
                    <label>{translate('client-step01-company-size')}</label>
                    <InputSelect
                        name="EmployeesNumber"
                        options={selectEmployeesNumber}
                        lang={currentLanguage}
                    />
                </FormGroup>

                <FormGroup>
                    <ServiceTerms>
                        <span>
                            {translate('terms-of-service-agreement1')}
                            <strong>
                                {translate('terms-of-service-agreement2')},
                            </strong>
                            {translate('terms-of-service-agreement3')}

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={useIntl().formatMessage({
                                    id: 'terms-of-service-link',
                                })}
                            >
                                {translate('terms-of-service-agreement4')}
                            </a>
                        </span>
                    </ServiceTerms>
                </FormGroup>

                <FormGroup>
                    <Captcha
                        sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                        removeCaptchaError={handleCaptcha}
                    />
                    {reCaptchaError && (
                        <Error>
                            <span>{translate('recaptcha-is-required')}</span>
                        </Error>
                    )}
                </FormGroup>

                { alertError && <FormGroup>
                        <Alert type="danger" icon={true}>
                            <>
                                <div>{alertError.message}</div>
                                <div>Caso o erro persista, entre em contato com o <SupportLink />. </div>
                            </>
                        </Alert>
                    </FormGroup> }


                <Footer>
                    <Button
                        id="submitStep1"
                        type="submit"
                        primary
                        isLoading={nextStepDisabledButton}
                        disabled={isLoadingButton}
                    >
                        {translate('accessiClips-button')}
                    </Button>
                </Footer>
            </Form>
        </StepVisibleControl>
    );
};

export default CustomerEmail;
